const theme = {
  color: {
    darkGreen: '#002723',
    green: '#536B62',
  },
  space: {
    main: {
      desktop: 165,
      mobile: 15,
    },
    safe: {
      mobile: 34,
    },
  },
  font: {
    large: {
      mobile: {
        size: 22,
      },
      desktop: {
        size: 26,
      },
    },
    small: {
      mobile: {
        size: 16,
      },
      desktop: {
        size: 18,
      },
    },
  },
}

export default theme
