import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { StoreProvider } from './src/components/store'
import theme from 'components/styles/theme'
import '@fontsource/dm-sans'
import 'scss/layout.scss'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider>{element}</StoreProvider>
    </ThemeProvider>
  )
}
