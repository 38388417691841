import React, { createContext, useContext, useReducer } from 'react'
import { Role } from 'types/custom'

const initialState = { applicationType: null, userData: null, userRole: null }

interface State {
  applicationType: 'application' | 'noticeOfInterest' | null
  userData: { personalNumber: string } | null
  userRole: Role | null
}

type Action =
  | {
      type: 'setApplicationType'
      data: 'application' | 'noticeOfInterest' | null
    }
  | {
      type: 'setUserData'
      data: { personalNumber: string }
    }
  | {
      type: 'setUserRole'
      data: Role
    }

interface ContextProps {
  state: State
  dispatch: ({ type, data }: Action) => void
}
const StoreContext = createContext({} as ContextProps)

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setApplicationType':
      return {
        ...state,
        applicationType: action.data,
      }
    case 'setUserData':
      return {
        ...state,
        userData: action.data,
      }
    case 'setUserRole':
      return {
        ...state,
        userRole: action.data,
      }
    default:
      throw new Error(`Invalid action type`)
  }
}

export const StoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = (): ContextProps => useContext(StoreContext)
